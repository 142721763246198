table {
    box-shadow: 0px 0px 5px 0px #d1d1d1;
    position: relative;

    th {
        font-weight: 400;
    }

    th,
    td {
        vertical-align: middle;
    }

    .id {
        width: 5%;
        text-align: center;
    }

    .options {
        width: 80px;
        text-align: center;
    }

    td.picture {
        text-align: center;
        width: 60px;

        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
        }
    }

    td.group-left {
        text-align: right;
        padding-right: 2px;
    }

    td.group-right {
        text-align: left;
        padding-left: 2px;
    }

    .th {
        background: #eee;
        text-align: right;
        font-weight: 300;
    }

    tr.clickable td {
        cursor: pointer;
    }

    tr.deleted {

        td:not(.options),
        td:not(.options) * {
            color: red !important;
            text-decoration: line-through;
        }
    }

    tr.disabled {
        pointer-events: none;
    }

    td {
        .link {
            cursor: pointer;
            color: #0d6efd;
        }

        b {
            font-weight: 500;
        }
    }

    tr:hover {
        td {
            background: #eee;
        }
    }

    th.sortable {
        cursor: pointer;

        &:hover,
        &.active {
            background: #eee;
        }
    }

    tr.clicked {
        td {
            background: #fbf6c6;
            box-shadow: none;
        }
    }

    table.nested {
        width: calc(100% - 50px);
        border: 1px solid #e9e8ed;
        border-radius: 0;
        cursor: auto;
        margin-left: 50px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: calc(100% - 22.5px);
            background-color: #ccc;
            left: -24.5px;
            top: 0;
        }

        td:nth-child(1) {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                width: 25px;
                height: 1px;
                background-color: #ccc;
                left: -25px;
                top: calc(50% - 0.5px);
            }
        }
    }
}

table.table {
    margin-top: 0;
    margin-bottom: 0;
}

.table-responsive {
    box-shadow: 0px 0px 5px 0px #d1d1d1;

    table {
        box-shadow: none;
    }
}

table.no-shadow {
    box-shadow: none;
}

table.no-hover {
    tr {
        td {
            pointer-events: none;
        }
    }
}

.table> :not(caption)>*>* {
    padding: 0.5rem 0.8rem;
}

.table.big> :not(caption)>*>* {
    padding: 0.8rem 0.4rem;
    font-size: 0.8rem;
}

.table.xxl> :not(caption)>*>* {
    padding: 0.6rem 0.4rem;
    font-size: 0.8rem;
}

table.valign-top {
    td {
        vertical-align: top;
    }
}

table {
    tr.total {
        td {
            background-color: #e7e7e7;
            font-weight: 500;
        }
    }

    tr.total-secondary {
        td {
            background-color: #cdcdcd;
            font-weight: 500;
        }
    }
}

.form-panel {
    table {
        box-shadow: none;
    }
}

table.small {

    th,
    td {
        font-size: 0.8rem;
    }

    > :not(caption)>*>* {
        padding: 0.3rem 0.4rem;
    }

    // buttons
    .btn-group-sm>.btn,
    .btn-sm {
        padding: 0.2rem 0.3rem;
        font-size: 0.65rem;
    }
}

.table-responsive {
    background-image:

        /* Shadows */
        linear-gradient(to right, white, white), linear-gradient(to right, white, white),
        /* Shadow covers */
        linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

    @media screen and (min-width: 1600px) {
        table {
            // min-width: 1500px;
        }
    }
}

.card-body {
    table {
        margin: -1px -1px;
        width: calc(100% + 2px);
        font-size: 0.85rem;
    }
}

button.table-option-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    padding: 0.2rem;
    font-size: 1rem;
    margin: 0 1.5px;
}