#dashboard {
    .card.widget-flat {
        position: relative;
        overflow: hidden;
        flex: 1 1 auto;
        padding: 1rem;

        .text-muted {
            color: #8a969c;
        }

        h5 {
            color: #000 !important;
        }

        h3 {
            color: #585858;
            font-weight: 300;
        }

        .fw-normal {
            font-weight: 300 !important;
        }
    }


    .card {
        // box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
        box-shadow: 0px 0px 5px 0px #d1d1d1;
        // padding: 20px;
        border-radius: 6px;
        // box-shadow: 0px 0px 5px 0px #bbb;

        margin-bottom: 1.5rem;
        border: none !important;

        .card-header {
            border-bottom: none;
            padding: 1.125rem 1.5rem;
            background-color: #fff;
            margin-bottom: 0;

            .header-title {
                margin-bottom: 0.5rem;
                text-transform: uppercase;
                letter-spacing: .02em;
                font-size: .9rem;
                margin-top: 0;
            }
        }

        .card-header .header-title,
        .card-title .header-title {
            margin-bottom: 0;
        }

        .chart-content-bg {
            background-color: #f6f7fb;
            margin: 0.5rem;
            margin-bottom: 2rem;
            width: 100%;
            height: fit-content;

            h2 {
                display: inline-flex;
                align-items: center;

                .circle {
                    width: 8px;
                    height: 8px;
                    // border: 1px solid #000;
                    background-color: #000;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                span {
                    color: #585858;
                    font-weight: 300;
                }
            }
        }

        .chart {
            display: flex;
            flex-wrap: wrap;

            .graph {
                padding: 1rem;
                margin-top: auto;
                width: 100%;
            }

            .chart-widget-list {
                padding: 1rem;
                width: 100%;

                p {
                    border-bottom: 1px solid #eee;
                    margin-bottom: 0.5rem;
                    padding-bottom: 0.5rem;

                    i {
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .card-body.with-table {
            padding: 0;

            .table-responsive {
                box-shadow: none !important;

                table {
                    margin: 0 !important;
                    width: 100% !important;
                }
            }
        }
    }

    .card-h-100 {
        height: calc(100% - 1.5rem) !important;
    }
}