:root {
    // --bs-body-font-size: 0.9rem;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
    // border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    // border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background: #f3f3f5;
}

html {
    height: -webkit-fill-available;
    font-size: 90%;
}

@media screen and (max-width: 1500px) {
    html {
        font-size: 85%;
    }
}

@media screen and (max-width: 1280px) {
    .gt-1280 {
        display: none !important;
    }


}

@media screen and (min-width: 1280px) {
    .lw-1280 {
        display: none !important;
    }
}

nav.sidebar {
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

nav.navbar {
    /* height: 7 px; */
    background: #fff;
    border-bottom: #e0dfe0 1px solid;
    -webkit-box-shadow: 0px 5px 5px 0px rgb(224, 223, 224);
    -moz-box-shadow: 0px 5px 5px 0px rgba(224, 223, 224, 1);
    box-shadow: 0px 5px 5px 0px rgb(224, 223, 224);
    padding: 0;
    padding-left: 25px;
    padding-right: 25px;

    .container-fluid {
        flex: 1;
        align-items: initial;
        justify-content: initial;

        .left,
        .right {
            display: flex;
            flex: 1;
        }

        .left {
            margin-right: auto;
        }

        .right {
            margin-left: auto;
            display: flex;
            flex: 1 1;
        }

        .right {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;

            input[type="text"] {
                border-color: #fff;
                color: #42424e;
                background-image: url("../img/icons/icons/search.svg");
                background-position: 10px center;
                background-repeat: no-repeat;
                background-size: 13px;
                padding-left: 25px;

                //   width: auto;
                &::placeholder {
                    color: #c0c3dc;
                }

                &:focus {
                    border-color: #c0c3dc;
                }
            }

            .notifications {
                position: relative;
                margin-left: 20px;

                audio {
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    left: -9999px;
                    top: -9999px;
                }

                .bell {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    cursor: pointer;

                    span {
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        border: 2px solid #fff;
                        background-color: #ffc700;
                        position: absolute;
                        top: -1px;
                        right: -3px;
                        display: block;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .list {
                    position: absolute;
                    right: 0;
                    top: calc(100% + 10px);
                    background: #ffffff;
                    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    min-width: 300px;

                    h3 {
                        margin: 0;
                        padding: 15px 10px;
                        border: 1px solid #e2eaef;
                        font-size: 16px;
                        font-weight: normal;
                    }

                    .no-results {
                        text-align: center;
                        padding: 50px 0;

                        img {
                            width: 100px;
                            margin-bottom: 10px;
                        }

                        b {
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }

                    ul {
                        height: 250px;
                        overflow: auto;
                        margin: 0;

                        li {
                            height: auto;
                            padding: 15px 10px;
                            border-bottom: 1px solid #e2eaef;
                            cursor: pointer;
                            align-items: flex-start;
                            margin-bottom: 0;

                            .icon {
                                width: 35px;
                                height: 35px;
                                flex-shrink: 0;
                                margin-right: 10px;
                                background-color: #f74e4e;
                                border-radius: 50%;
                                padding: 5px;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            .right {
                                align-items: flex-start;
                                justify-content: flex-start;
                                display: flex;
                                flex-wrap: wrap;

                                h6 {
                                    font-weight: normal;
                                    font-size: 12px;
                                    width: 100%;
                                }

                                span {
                                    color: #aeb7c9;
                                    font-size: 13px;
                                }
                            }

                            &.unread {
                                .right {
                                    h6 {
                                        font-weight: 600;
                                    }

                                    span {
                                        // 
                                    }
                                }
                            }

                            &.removed {
                                cursor: default;
                            }

                            &:hover {
                                background-color: rgb(252, 252, 252);
                            }

                            &:last-of-type {
                                border: none;
                            }
                        }
                    }

                    .mark-as-read {
                        width: 100%;
                        height: 100%;
                        background-color: #fcfcfc;
                        border: none;
                        border-top: 1px solid #ccc;
                        // box-shadow: 0px -4px 10px #fcfcfc;
                        color: #42424e;
                        z-index: 1;
                        position: relative;
                        padding: 5px 0;

                        &:hover {
                            background-color: #fcfcfc;
                        }
                    }
                }
            }

            a.user,
            span.logout {
                margin-left: 20px;
                cursor: pointer;
            }

            a.user {
                img {
                    height: 35px
                }
            }
        }
    }

    .navbar-brand {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .store-context {
        margin: auto 0;

        .link {
            color: var(--bs-body-color);
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;

            svg {
                font-size: 12px;
            }
        }
    }

    // .nav {
    //     margin-left: auto;
    // }

    ul {
        list-style-type: none;
        padding: 0;
        flex-wrap: wrap;
        z-index: 10;

        &.main {
            >li {
                min-width: 85px;

                >a {
                    width: 100%;

                    >img {
                        margin-right: 0;
                        margin-bottom: 5px;
                        width: 30px;
                        height: 30px;
                        flex-shrink: 0;
                        object-fit: scale-down;
                        display: block;
                        max-height: initial;
                    }

                    >nav.link {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                    }
                }

                >span.nav-link,
                >a.nav-link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    >img {
                        margin-right: 0;
                        margin-bottom: 5px;
                        width: 25px;
                        height: 25px;
                        flex-shrink: 0;
                        object-fit: scale-down;
                        display: block;
                        max-height: initial;
                    }

                    >svg {
                        display: none;
                    }
                }

                &::after {
                    display: none;
                }
            }
        }

        >li {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;

            .nav-link {
                color: #000;
            }

            &:not(:last-of-type)::after {
                content: "";
                background: #eee;
                width: 1px;
                height: 40%;
            }

            a,
            span {
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 100%;

                .caret {
                    margin-left: 3px;
                    font-size: 12px;
                    margin-top: 3px;
                }

                .icon {
                    margin-right: 10px;
                    margin-top: 3px;
                    width: 20px;
                    max-height: 16px;
                    object-fit: contain;
                }
            }

            ul {
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 120px;
                background: #fff;
                border: #e0dfe0 1px solid;
                display: none;
                -webkit-box-shadow: 0px 5px 5px 0px rgb(224, 223, 224);
                -moz-box-shadow: 0px 5px 5px 0px rgba(224, 223, 224, 1);
                box-shadow: 0px 5px 5px 0px rgb(224, 223, 224);

                li {
                    width: 100%;
                    max-width: max-content;
                    min-width: 200px;
                    position: relative;
                    // text-align: right;

                    a,
                    span {
                        width: 100%;
                        position: relative;
                        color: var(--bs-body-color);
                        padding: 10px 15px;

                        .caret {
                            position: absolute;
                            top: 50%;
                            right: 5px;
                            transform: translate(0, -50%);
                        }
                    }

                    ul {
                        top: 0;
                        left: 80%;
                        z-index: 2;
                    }
                }
            }

            ul.right {
                right: 0;
                left: auto;
            }

            &:hover {
                background: #f9f9f9;

                >ul {
                    display: initial;
                }
            }
        }

        li.divider {
            position: relative;
            padding: 0 5px;
            pointer-events: none;
        }

        li.divider::after {
            content: "";
            background: #eee;
            width: 1px;
            height: 40%;
        }

        li.disabled {
            pointer-events: none;
        }
    }
}

@media screen and (max-width: 1500px) {
    nav.navbar {
        ul {
            li {

                span,
                a {
                    font-size: 0.85rem;

                    .icon {
                        width: 16px;
                        max-height: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}

nav.navbar .container {
    padding: 0;
}

.nav-pills {
    .nav-link {
        font-size: 0.9rem;
    }
}

.mobile-nav-btn {
    display: flex;
    align-items: center;

    button {
        border: none !important;
        background: transparent !important;
        color: #000 !important;
        font-size: 2rem;
    }
}

.mobile-nav {
    list-style-type: none;
    margin-left: -1rem;
    margin-right: -1rem;

    li {
        width: 100%;
        border-bottom: 1px solid #eee;
        font-size: 1.5rem !important;
        font-weight: 300;

        &:last-of-type {
            border-bottom: none;
        }

        .icon {
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-right: 5px;
        }

        .caret {
            font-size: 1rem;
        }

        a,
        span {
            color: #000;
            padding: 15px 20px;
        }

        ul {
            list-style-type: none;
            display: none;
            margin-left: 10px;

            .caret {
                transform: rotate(90deg);
            }
        }

        &:hover {
            >ul {
                display: block;
            }
        }
    }
}

main {
    /* width: 80%; */
    margin-left: auto;
}

.bi {
    vertical-align: -0.125em;
    pointer-events: none;
    fill: currentColor;
}

.dropdown-toggle {
    outline: 0;
}

.nav-flush .nav-link {
    border-radius: 0;
}

.nav-item a {
    cursor: pointer;
}

.btn {

    // display: inline-flex;
    // align-items: center;
    svg {
        // margin-right: 5px;
    }
}

.btn-dark {
    // background-color: blue;
}

.btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
    border: 0;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(0, 0, 0, 0.85);
    background-color: #d2f4ea;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, 0.85);
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    display: inline-flex;
    padding: 0.1875rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 1.25rem;
    text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: #d2f4ea;
}

.scrollarea {
    overflow-y: auto;
}

.fw-semibold {
    font-weight: 600;
}

.lh-tight {
    line-height: 1.25;
}

.pagination {
    .page-item.active .page-link {
        background-color: #212529 !important;
        color: #fff !important;
    }
}

.page-link,
.page-link:hover {
    color: #000;
}

.page-item.active .page-link {
    border: 1px solid #ced4da;
}

main .container-fluid {
    padding: var(--bs-gutter-x, 0.75rem);
}

.label {
    font-size: 75%;
    padding: 3px;
    border-radius: 3px;
    white-space: nowrap;
}

.overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
    transition: 0.3s;
    display: none;
    align-items: center;
    justify-content: center;
}

.visible {
    display: flex;
}

.hidden {
    display: none;
}

.page-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h1 {
        display: inline-flex;
        align-items: center;

        a {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }
    }

    .buttons {
        margin-left: auto;
    }
}

.page-filter {
    margin-bottom: 10px;
    align-items: center;
}

.form-panel {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px #bbb;

    .head {
        font-weight: 600;
        display: block;
        margin-bottom: 20px;
    }

    &.full-height {
        height: 100%;
    }
}

.table-options {}

.pagination-with-options {
    display: flex;
    margin-bottom: 10px;

    .table-options {
        margin-left: auto;
        display: flex;

        button {
            height: 100%;

            span {
                font-size: 0.85rem;
            }
        }
    }
}

.columns-options {
    table {
        vertical-align: middle;

        td {
            padding: 2px;
            vertical-align: middle;
        }
    }

    .sort {
        width: 15px;
        height: 15px;
        background-color: #E8EAF5;
        border: 1px solid #ACB3D0;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0d6efd;

        &:hover {
            border-color: #0d6efd;
        }
    }
}

.box-shadow {
    box-shadow: 0px 0px 5px 0px #bbb;
}

form {
    .buttons {
        display: flex;
        justify-content: flex-end;

        &.left {
            justify-content: flex-start;
        }

        &.space-between {
            // justify-content: space-between;
        }

        button {
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.dropdown-menu {
    margin-top: 10px;
    box-shadow: 0px 5px 5px #ccc;
    border: none;
    font-size: 0.9rem;

    &.end {
        right: 0;
    }
}

.dropdown-options {
    .dropdown {
        .dropdown-toggle {
            padding: 5px;
            display: inline-flex;
            font-size: 17px !important;
            cursor: pointer;
            line-height: 1;
        }

        &.show {
            .dropdown-toggle {
                background: #fff;
            }
        }
    }

    .dropdown-toggle::after {
        display: none;
    }
}

.dropdown-menu-options {
    margin-top: -5px;
    border-radius: 0 !important;
    border: none !important;
    width: fit-content;
    min-width: fit-content;
    z-index: 9999;

    a {
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom: none;
        }
    }
}

.validate {
    border-top-color: #dc3545 !important;
    color: #dc3545 !important;
}

.cat-btn {
    margin-left: 10px;
    padding: 0 5px;
    display: none;
    position: absolute;
}

.alert-wrapper {
    position: fixed;
    bottom: 0;
    right: 20px;
    left: 20px;
    // width: max-content;
    pointer-events: none;
    z-index: 1056; // повече от модала

    .alert {
        pointer-events: all;
        max-width: 350px;
        margin-left: auto;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.tooltip {
    .tooltip-arrow {}

    .tooltip-inner {
        line-height: 1.3;
        text-align: left;
        width: fit-content;
        background-color: #202020;
        // color: #000;
        // border: 1px solid #ced4da;
        border-radius: 3px;
        box-shadow: 1px 4px 10px #101010;
        max-width: 250px;
    }
}

.tooltip.transparent {
    .tooltip-inner {
        background: none;
    }
}

.tooltip.file-preview {
    .tooltip-inner {
        background: #fff;
        padding: 0;
    }

    .tooltip-arrow {
        display: none;
    }
}

.tooltip.transparent,
.tooltip.no-arrow {
    .tooltip-arrow {
        display: none;
    }
}

.custom-radio {
    display: inline-flex;
    padding: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;

    label {
        cursor: pointer;
        display: inline-flex;

        input {
            display: none;
        }

        span {
            padding: 3px 5px;
            font-size: 0.875rem;

            &:hover {
                background-color: #eee;
                // color: #fff;
            }
        }

        input:checked+span {
            background-color: #0d6efd;
            color: #fff;
        }
    }

    label:first-of-type {
        span {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    label:last-of-type {
        span {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}

input[type="number"].price-input {
    text-align: right;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
}

input.negative-value,
td.negative-value {
    background-color: #ff8a957a !important;
    color: #9d1320 !important;
}

span.negative-value {
    color: #9d1320 !important;
}

input.green-value,
td.green-value,
input.positive-value,
td.positive-value {
    background-color: #35f56c8f !important;
    color: #008526 !important;
}

input.blue-value,
td.blue-value {
    background-color: #107fff54 !important;
    color: #3b7ef9 !important;
}

input.lightblue-value,
td.lightblue-value {
    background-color: #16c6f5 !important;
    color: #fff !important;
}

input.brown-value,
td.brown-value {
    background-color: #79554887 !important;
    color: #604338 !important;
}

input.yellow-value,
td.yellow-value {
    background-color: #ddd275 !important;
    box-shadow: none;
}

input.modified-value,
td.modified-value {
    background-color: #ffc10724 !important;
}

.form-floating {
    .tooltip-info-icon {
        position: absolute;
        top: 3px;
        right: 3px;
        display: inline-flex;
        background: #fff;
        border-radius: 50%;
        padding: 1px;
        align-items: center;
        justify-content: center;
        // box-shadow: 0 0 5px #cdcdcd;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    .locked-filter {
        position: absolute;
        // top: -5.5px;
        // left: -7.5px;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        background: #fff;
        // border: 1px solid #ced4da;
        // border-radius: 50%;
        border-radius: 3px;
        padding: 2px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;

        &:hover {
            // border-color: #86b7fe;
            outline: 0;
            // box-shadow: 0 0 0 0.15rem rgb(13 110 253 / 25%);
            // opacity: .7;
            scale: 120%;
            top: 60%;
            transform: translate(0, -60%);
        }

        &.session {
            background-color: #fff3cf;
            color: #ad913c;
        }

        &.local {
            background-color: #ffe5e5;
            color: #b37272;
        }


    }

    input {
        &:focus {
            // ~.locked-filter {
            //     border-color: #86b7fe;
            //     outline: 0;
            //     box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
            // }
        }
    }
}

.form-check {
    &.red {
        label {
            background-color: #ff8a957a !important;
            color: #9d1320 !important;
        }
    }
}

.form-check.rotated {
    display: inline-flex;
    flex-direction: column;
    height: 185px;
    justify-content: flex-end;

    input {
        order: 2;
        display: flex;
    }

    label {
        order: 1;
        transform: translate(-22px, 0px) rotate(186deg);
        writing-mode: vertical-rl;
        display: flex;
        margin-bottom: 5px;
    }
}

.form-switch {}

.form-switch.yellow {
    .form-check-input {
        border-color: #c3a73a;
    }

    .form-check-input:checked {
        background-color: #c3a73a;
        border-color: #c3a73a;
    }
}

p.markers {
    margin: 0;
    font-size: 0.875rem;

    span {
        cursor: pointer;
    }
}

.rst__rowLabel {
    width: 100%;

    .category-options {
        display: flex;
        justify-content: flex-end;
    }
}

.ck.ck-balloon-panel {
    z-index: 9999 !important;
}

.card-body {
    &.disabled {
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.05);
    }
}

// .card-body {
//     ul {
//         padding-left: 1.5rem;

//         li {
//             color: #eee;
//             font-weight: 300;
//             font-size: 0.9rem;
//         }
//     }
// }

.color-block {
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.depots-filter {
    .all-options {
        margin-top: 10px;

        button {
            // margin-right: 3px;
            // border-radius: 0;
            padding: 0.15rem;

            &.btn-secondary {
                background-color: #eee;
                border: none;
                color: #212529;

                &:hover {
                    background-color: #bbb;
                }
            }
        }
    }

    .header {
        display: flex;
        background-color: cornsilk;
        padding: 3px;

        div {
            width: 50%;
        }
    }

    .body {
        display: flex;
        align-items: flex-start;
        background: #eee;

        .stores {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin-right: 5px;

            .store {
                width: 100%;
                padding: 5px;
                background: #fff;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
                margin-top: -1px;
                cursor: pointer;

                &:first-of-type {
                    border-top: 1px solid transparent;
                }

                &:last-of-type {
                    border-bottom: 1px solid transparent;
                }

                &.active {
                    background: transparent;
                    border: 1px solid transparent;
                }

                label {
                    span.has-selected-depot {
                        color: #0d9efe;
                    }

                    span.deleted {
                        color: red !important;
                        text-decoration: line-through;
                    }
                }
            }
        }

        .depots {
            width: 50%;
            flex-direction: column;
            display: none;

            &.active {
                display: flex;
            }

            .depot {
                width: 100%;
                padding: 5px;
            }
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;

            input {
                margin-right: 10px;
            }
        }
    }
}

.details-filter {
    display: flex;
    // flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 300px;
    overflow-y: auto;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    .detail {
        // height: 100%;
        background: cornsilk;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 4px;
        width: calc(100% / 2 - 10px);

        .title {
            border-bottom: 1px solid #ccc;
            padding-bottom: 3px;
        }

        .body {
            margin-top: 10px;
            max-height: 150px;
            overflow-y: auto;

            label {
                display: flex;
                align-items: center;

                span {
                    margin-left: 3px;
                }
            }
        }
    }
}

.flash-messages {
    position: fixed;
    bottom: 20px;
    // top: 105px;
    // left: 50px;
    right: 50px;
    z-index: 1056;
    background: transparent;

    .alert {
        width: fit-content;
        min-width: 400px;
        margin-left: auto;
        margin-right: auto;

        .body {
            display: flex;
            align-items: center;

            .text {
                margin-left: 10px;
            }
        }

        .btn-close {
            background-size: 0.7em;
        }
    }

    .message {
        margin-top: 10px;
        transition: .3s;
        -webkit-transform: translateY(16px);
        transform: translateY(16px);
        -webkit-transform-origin: bottom right;
        transform-origin: bottom right;
        opacity: 0;
        visibility: hidden;

        &.visible {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }

    .success {
        background-color: #35f56ce6 !important;
        color: #008526 !important;
        box-shadow: 0 0 3px #008526;
        padding: 10px 30px;
        border-radius: 3px;
    }
}

.modal {
    .arrow {
        position: fixed;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 1;
        font-size: 2rem;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        &:hover {
            color: #000;
        }

        &.left {
            left: 10px;
        }
        &.right {
            right: 10px;
        }
    }

    .modal-header {
        .buttons {
            display: flex;
            align-items: center;

            button {
                box-sizing: content-box;
                width: 1em;
                height: 1em;
                padding: 0.25em 0.25em;
                color: #000;
                border: 0;
                border-radius: 0.375rem;
                opacity: .7;
                &:hover {
                    opacity: 1;
                }
            }

            .btn-reload {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' class='bi bi-arrow-clockwise' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'/%3E%3Cpath d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z'/%3E%3C/svg%3E") center/1em auto no-repeat;
            }
            .btn-close {
                background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/%3E%3C/svg%3E") center/1em auto no-repeat;
            }
        }
    }
}

$starColor: invert(62%) sepia(103%) saturate(1900%) hue-rotate(384deg) brightness(154%) contrast(110%);

.rating-holder {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
        margin: 0;
    }

    ul.rating {
        list-style: none;
        display: flex;
        padding-left: 0px;
        margin-bottom: 0px;

        li {
            img {
                pointer-events: none;
                height: 25px;
            }
        }

        &.r1 {
            li:nth-child(1) {
                img {
                    filter: $starColor;
                }
            }
        }

        &.r2 {

            li:nth-child(1),
            li:nth-child(2) {
                img {
                    filter: $starColor;
                }
            }
        }

        &.r3 {

            li:nth-child(1),
            li:nth-child(2),
            li:nth-child(3) {
                img {
                    filter: $starColor;
                }
            }
        }

        &.r4 {

            li:nth-child(1),
            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4) {
                img {
                    filter: $starColor;
                }
            }
        }

        &.r5 {

            li:nth-child(1),
            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4),
            li:nth-child(5) {
                img {
                    filter: $starColor;
                }
            }
        }
    }
}

.reviews-files {
    display: flex;
    gap: 5px;

    .file {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            pointer-events: none;
        }
    }
}

.link {
    color: #0d6efd;
    cursor: pointer;
}

.form-datepicker {
    input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='1em' height='1em' fill='currentColor'%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 12px 12px;
    }

    .is-invalid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='1em' height='1em' fill='currentColor'%3E%3Cpath d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z'%3E%3C/path%3E%3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
        background-position: right 0.75rem center, center right 2.25rem;
        background-size: 12px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}

.form-datepicker.time {
    input {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-clock' viewBox='0 0 16 16'%3E%3Cpath d='M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z'/%3E%3Cpath d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z'/%3E%3C/svg%3E");
    }

    .is-invalid {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-clock' viewBox='0 0 16 16'%3E%3Cpath d='M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z'/%3E%3Cpath d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    }
}

.form-select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.floating-search {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 0px 5px 0px #d1d1d1;
    cursor: pointer;
    font-size: 18px;
    box-shadow: 5px 5px 5px 0px rgb(224, 223, 224);
    border: #e0dfe0 1px solid;

    &:hover {
        opacity: .8;
    }
}

.baloon-selected {
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    position: fixed;
    bottom: 25px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 1rem;
    color: #212529;
    -webkit-animation: baloon-selected .3s ease-in-out;
    animation: baloon-selected .3s ease-in-out;
    -webkit-flex-direction: column;
    flex-direction: column;
    box-shadow: 0px 5px 5px #ccc;

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #ccc;
        padding-top: 10px;

        button {
            // padding: 10px;
            // line-height: 1;
            // border-radius: 6px;
            height: auto;

            // svg {
            //     display: block;
            //     margin: auto;
            // }
        }
    }
}

@keyframes baloon-selected {
    0% {
        opacity: 0;
        bottom: 0;
    }

    100% {
        opacity: 1;
        bottom: 25px;
    }
}