.modal-90w {
    max-width: 1440px !important;
}

.col-centered {
    display: flex;
    flex-direction: column;
}

.mb-n10 {
    margin-bottom: -10px !important;
}

.mt-p20 {
    margin-top: 20px !important;
}

.childrens {
    opacity: .5;
    pointer-events: none;

    input {
        filter: none;
    }

}
