.mass-update-options {
    display: flex;
    align-items: center;
    gap: 5px;

    >div {
        display: flex;
        align-items: center;
        gap: 5px;

       
    }

    span {
        font-size: 0.8rem;
        white-space: nowrap;
    }

    button {
        // border: none;
    }

    .disabled {
        display: none;
    }
}