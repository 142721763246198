.area-wrapper {
    overflow-x: auto;
    padding-bottom: 10px;

    background-image:

        /* Shadows */
        linear-gradient(to right, white, white), linear-gradient(to right, white, white),
        /* Shadow covers */
        linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

    .area {
        border: 1px solid #000;
        margin: auto;
        position: relative;
        font-family: Arial !important;

        .element {
            position: absolute;
            cursor: move;
            background-color: rgba(240, 248, 255, .5);
            z-index: 1;

            &.active {
                border: 0.5px solid orange;
            }
        }

        .grid {
            background-image: url('/assets/img/grid.png');
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.active-element {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 10px;

    .buttons {
        button {
            width: 25px;
            height: 25px;
            line-height: 1;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.bold {
                font-weight: bold;
            }

            &.italic {
                font-style: italic;
            }

            &.underline {
                text-decoration: underline;
            }

            &.line-through {
                text-decoration: line-through;
            }

            &.left,
            &.center,
            &.right {
                // 
            }
        }

        select {
            width: auto;
            margin-left: 10px;
        }
    }

    .inputs {
        .group {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            button {
                display: block;
            }
        }

        label {
            font-size: 0.85rem;
        }
    }
}