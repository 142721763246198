.files {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    .file-container {
        position: relative;
        display: block;
        width: calc(25% - 20px);

        .file {
            position: relative;
            width: 100%;
            aspect-ratio: 1/1;
            background-color: #eee;
            border-radius: 3px;

            // &:before {
            //     content: "";
            //     display: block;
            //     padding-top: 100%; /* initial ratio of 1:1*/
            // }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: inherit;
            }

            .buttons {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: none;
            }
        }

        .validate {
            font-size: 11px;
        }

        &:hover {
            .buttons {
                display: initial;
            }
        }
    }
}

.categories {
    .master {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #0d6efd;
        color: #0d6efd;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }
}